import React, { Component } from "react";
import { connect } from "react-redux";

import "./style.css";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";
import DashboardMap from "./DashboardMap";
import RoadMap from "./Analysis/RoadMap";
import { X } from "@mui/icons-material";
import {
  getPropertyID,
  getSecondDashboardData,
  getWardList,
} from "./data/action";
// import { PieChart } from "@mui/icons-material";
// import { Button, Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
// import Form from 'react-bootstrap';
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   CategoryScale,
//   Legend,
//   LinearScale,
//   BarElement,
// } from "chart.js";
// import { Pie, Bar } from "react-chartjs-2";
// import { getData } from "./data";
// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   LinearScale,
//   BarElement,
//   CategoryScale
// );

class SecondDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area_range: [0, 2800000],
      page: 0,
      rowsPerPage: 10,
      ward_id: null,
    };
  }


  componentDidMount() {
    this.props.getWardList();
    this.props.getSecondDashboardData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ward_id !== prevState.ward_id) {
      this.props.getSecondDashboardData(this.state.ward_id);
    }
    // if (
    //   !this.state.ward_id &&
    //   this.props.wardList &&
    //   prevProps.wardList !== this.props.wardList
    // ) {
    //   const firstWardId = Object.keys(this.props.wardList)[0];
    //   this.setState({ ward_id: firstWardId });
    //   console.log("firstWardID componentDidUpdate", firstWardId);

    //   this.props.getSecondDashboardData(firstWardId);
    // }
    if (this.state.area_range !== prevState.area_range) {
      let data = null;

      if (this.state.ward_id) {
        data = {
          max_area: this.state.area_range[1],
          min_area: this.state.area_range[0],
          ward: parseInt(this.state.ward_id),
        };
      } else {
        data = {
          max_area: this.state.area_range[1],
          min_area: this.state.area_range[0],
        };
      }
      this.props.getPropertyID(data);
    }

    // if(this.props.property_id !== null && this.props.property_id.length > 0){
    //   console.log("property details",this.props.property_id);
      
    //   this.setState({

    //   })
    // }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  handleWardChange = (e) => {
    this.setState((prev) => ({
      ...prev,
      ward_id: e.target.value,
    }));
  };

  areaTableData = (property_id, floor_area, assessment_area, area_diff) => {
    return { property_id, floor_area, assessment_area, area_diff };
  };

  handleRangeChange = (event, value) => {
    console.log("value", value);
    this.setState({
      area_range: value,
      //   max_area:value[1],
      // min_area:value[0]
    });
  };

  render() {
    const barData = {
      labels: ["Category 1"],
      datasets: [
        {
          label: "Value A",
          data: [30], // Value for Category 1
          backgroundColor: "#36A2EB",
        },
        {
          label: "Value B",
          data: [50], // Value for Category 1
          backgroundColor: "#FF6384",
        },
        {
          label: "Value C",
          data: [20], // Value for Category 1
          backgroundColor: "#FFCE56",
        },
      ],
    };

    // Options for the chart
    const barOptions = {
      indexAxis: "y", // Makes the bar chart horizontal
      scales: {
        x: {
          stacked: true, // Stacks the bars horizontally
          beginAtZero: true,
        },
        y: {
          stacked: true, // Stacks the bars
        },
      },
      responsive: true,
      plugins: {
        legend: {
          position: "top", // Position of the legend
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
        title: {
          display: true,
          text: "Single Stacked Horizontal Bar Chart",
        },
      },
    };

    const options = {
      rotation: -90,
      circumference: 180,
      cutout: "70%",
    };

    const secondDahboardData = this.props.secondDashboardData;

    // doughnut building area

    const percentage =
      (secondDahboardData?.floor_prop_area_gt_250 /
        secondDahboardData?.building_count_asper_IPMS) *
      100;
    const totalValue = secondDahboardData?.building_count_asper_IPMS;
    const centerValue = secondDahboardData?.floor_prop_area_gt_250;

    const buildingAreaDoughnut = {
      datasets: [
        {
          data: [percentage, 100 - percentage],
          backgroundColor: ["#4bc0c0", "rgba(0, 0, 0, 0)"],
          borderWidth: 0,
          hoverOffset: 4,
        },
      ],
    };

    // bar chart doorStatus

    const doorStatusData = secondDahboardData?.door_status_counts;
    const doorStatusLabels = [];
    doorStatusData &&
      Object.keys(doorStatusData).map((key) => doorStatusLabels.push(key));
    const doorStatusValues = [];
    doorStatusData &&
      Object.keys(doorStatusData).map((key) =>
        doorStatusValues.push(doorStatusData[key])
      );

    const doorStatusBarChart = {
      labels: doorStatusLabels,
      datasets: [
        {
          data: doorStatusValues,
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(235, 149, 52,0.6)",
            "rgba(164, 194, 76,0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(235, 149, 52,1)",
            "rgba(164, 194, 76,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const doorStatusOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 6,
            },
            color: "#333",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            color: "#333",
          },
        },
      },
      layout: {
        padding: 8,
      },
    };

    // properties vs ration card

    const rationCardColorData = secondDahboardData?.ration_card_colors;

    const rationCardColorLabels = [];
    rationCardColorData &&
      Object.keys(rationCardColorData).map((key) =>
        rationCardColorLabels.push(key)
      );
    const rationCardColorValues = [];
    rationCardColorData &&
      Object.keys(rationCardColorData).map((key) =>
        rationCardColorValues.push(rationCardColorData[key])
      );

    const rationCardColorDoughnutChart = {
      labels: rationCardColorLabels,
      datasets: [
        {
          data: rationCardColorValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const rationCardColorOptions = {
      plugins: {
        legend: {
          display: true, // Show or hide legend
          position: "right",
          font: {
            size: 12,
          },
          labels: {
            font: {
              size: 14, // Legend font size
              weight: "bold", // Font weight
            },
            color: "#333", // Color of legend text
            padding: 15, // Space between legend items
            usePointStyle: true,
            pointStyle: "circle",
          },
          // radius:"90%"
        },
      },
    };

    const latrineData = secondDahboardData?.latrine_counts;

    const latrineLabels = [];
    latrineData &&
      Object.keys(latrineData).map((key) => latrineLabels.push(key));
    const latrineValues = [];
    latrineData &&
      Object.keys(latrineData).map((key) =>
        latrineValues.push(latrineData[key])
      );

    const latrineDoughnutChart = {
      labels: latrineLabels,
      datasets: [
        {
          data: latrineValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const latrineOptions = {
      plugins: {
        legend: {
          display: true, // Show or hide legend
          position: "right",
          font: {
            size: 12,
          },
          labels: {
            font: {
              size: 14, // Legend font size
              weight: "bold", // Font weight
            },
            color: "#333", // Color of legend text
            padding: 15, // Space between legend items
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    };

    // bathroom_counts

    const bathroomData = secondDahboardData?.bathroom_counts;

    const bathroomLabels = [];
    bathroomData &&
      Object.keys(bathroomData).map((key) => bathroomLabels.push(key));
    const bathroomValues = [];
    bathroomData &&
      Object.keys(bathroomData).map((key) =>
        bathroomValues.push(bathroomData[key])
      );

    const bathroomDoughnutChart = {
      labels: bathroomLabels,
      datasets: [
        {
          data: bathroomValues,
          backgroundColor: [
            "rgba(139, 212, 241, 0.6)",
            "rgba(202, 128, 130, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(240, 61, 21, 0.6)",
            "rgba(244, 139, 116, 0.6)",
            "rgba(249, 246, 245,0.6)",
            "rgba(245, 238, 104,0.6)",
          ],
          borderColor: [
            "rgba(139, 212, 241, 1)",
            "rgba(202, 128, 130, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(240, 61, 21, 1)",
            "rgba(244, 139, 116, 1)",
            "rgba(249, 246, 245,1)",
            "rgba(245, 238, 104,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const bathroomOptions = {
      plugins: {
        legend: {
          display: true, // Show or hide legend
          position: "right",
          font: {
            size: 12,
          },
          labels: {
            font: {
              size: 14, // Legend font size
              weight: "bold", // Font weight
            },
            color: "#333", // Color of legend text
            padding: 15, // Space between legend items
            usePointStyle: true,
            pointStyle: "circle",
          },
        },
      },
    };

    const ward_name = secondDahboardData?.ward_name;

    const ward_designation = secondDahboardData?.ward__designation_data;
    // console.log("secondDahboardData", secondDahboardData);

    const areaDiffTableData = secondDahboardData?.area_difference;

    const areaTableColumns = [
      { id: "title", label: "Property Id" },
      { id: "floor_area", label: "Floor" },
      { id: "assessment_area", label: "Assessment Area" },
      {
        id: "area_diff",
        label: "Area Difference",
      },
    ];

    let areaTableArray =
      areaDiffTableData &&
      Object.entries(areaDiffTableData).map(([title, columns]) => ({
        title,
        ...columns,
      }));

    console.log("are table values", areaTableArray);
    // areaTableArray?.map(())

    const maxItem = areaTableArray?.reduce((prev, current) => {
      return current.floor_area > prev.floor_area ? current : prev;
    });
    console.log("response max values", maxItem);

    const bldgCateGroupData = secondDahboardData?.bldg_category_counts;
    const bldgCateGroupLabels = [];
    bldgCateGroupData &&
      Object.keys(bldgCateGroupData).map((key) =>
        bldgCateGroupLabels.push(key)
      );
    const bldgCateGroupValues = [];
    bldgCateGroupData &&
      Object.keys(bldgCateGroupData).map((key) =>
        bldgCateGroupValues.push(bldgCateGroupData[key])
      );

    const bldgCateGroupBarChart = {
      labels: bldgCateGroupLabels,
      datasets: [
        {
          data: bldgCateGroupValues,
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(235, 149, 52,0.6)",
            "rgba(164, 194, 76,0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(235, 149, 52,1)",
            "rgba(164, 194, 76,1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const bldgCateGroupOptions = {
      indexAxis: "y", // This makes the chart horizontal
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 6,
            },
            color: "#333",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            color: "#333",
          },
        },
      },
      layout: {
        padding: 8,
      },
    };

    const schemaCountValues = secondDahboardData?.scheme_counts;

    let dataSetValues = [];
    let backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#EB9534",
      "#A4C24C",
      "#FFCE56",
    ];
    schemaCountValues &&
      Object.keys(schemaCountValues).map((key, i) => {
        if (key != "null") {
          let value = {
            label: key,
            data: [schemaCountValues[key]],
            backgroundColor: backgroundColors[i],
          };
          dataSetValues.push(value);
        }
      });

    const schemaCountBarData = {
      labels: ["Category 1"],
      datasets: dataSetValues,
    };

    console.log("datat values", areaTableArray);
    return (
      <>
        <Row className="second-dashboard-main-row">
          <Col lg={3} md={3} sm={3} xs={3} className="second-dashboard-col">
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">Select Ward:</span>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                {/* <InputLabel id="demo-select-small-label">Ward</InputLabel> */}
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={this.state.ward_id || null}
                  label="Ward"
                  // onChange={handleChange}
                  onChange={this.handleWardChange}
                >
                  {this.props.wardList &&
                    Object.keys(this.props.wardList).map((key, i) => (
                      <MenuItem key={i} value={key}>
                        {this.props.wardList[key]}
                      </MenuItem>
                    ))}
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">Total Building </span>
              <span className="second-dashboard-heading-key">
                As per IPMS:
                <span className="second-dashboard-heading-value">
                  {secondDahboardData?.building_count_asper_IPMS}
                </span>
              </span>
              <span className="second-dashboard-heading-key">
                As per AR:
                <span className="second-dashboard-heading-value">
                  {secondDahboardData?.building_count_asper_AR}
                </span>
              </span>
            </div>
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">
                Building Area with {">"} 250 sqm
              </span>
              <Doughnut data={buildingAreaDoughnut} options={options} />

              <div className="second-dashboard-doughnuts-values">
                <span className="second-dashoard-doughnut-start-value">0</span>
                <span className="second-dashoard-doughnut-center-value">
                  {centerValue}
                </span>

                <span className="second-dashoard-doughnut-end-value">
                  {totalValue}
                </span>
              </div>
            </div>
            <div className="second-dashboard-doorStatusBarChart">
              <span className="second-dashboard-heading">
                Count of Buildings by Door Status
              </span>
              <Bar data={doorStatusBarChart} options={doorStatusOptions} />
            </div>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6} className="second-dashboard-col">
            <Row className="second-dashboard-row">
              <Col
                className="second-dashboard-row-col"
                lg={6}
                md={6}
                sm={6}
                xs={6}
              >
                <div className="second-dashboard-btn">
                  <span className="second-dashboard-heading">
                    Building Area
                  </span>
                  <Slider
                    className="second-dashboard-slider-range"
                    value={this.state.area_range}
                    onChange={this.handleRangeChange}
                    valueLabelDisplay="auto"
                    min={0}
                    // max={2800}
                    max={maxItem?.area_diff}
                    // step={10}
                    marks={[
                      { value: 0, label: "0" },
                      {
                        value: maxItem?.area_diff,
                        label: `${maxItem?.area_diff}`,
                      },
                    ]}
                    // getAriaLabel={() => "Salary range"}
                  />
                </div>
                <div className="second-dashboard-btn">
                  <span className="second-dashboard-heading">
                    Properites Vs Ration Card Color
                  </span>

                  <Doughnut
                    data={rationCardColorDoughnutChart}
                    options={rationCardColorOptions}
                    height={300}
                    width={300}
                  />
                </div>
              </Col>
              <Col
                className="second-dashboard-row-col"
                lg={6}
                md={6}
                sm={6}
                xs={6}
              >
                <div className="second-dashboard-btn">
                  <span className="second-dashboard-heading">
                    Houses by scheme
                  </span>

                  <Bar data={schemaCountBarData} options={barOptions} />
                </div>
                <Row className="second-dashboard-pie-chart-row">
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="second-dashboard-row-col"
                  >
                    <div className="second-dashboard-btn">
                      <span className="second-dashboard-heading">
                        Count of Houses By Toilet
                      </span>

                      <Pie
                        data={latrineDoughnutChart}
                        options={latrineOptions}
                      />
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="second-dashboard-row-col"
                  >
                    <div className="second-dashboard-btn">
                      <span className="second-dashboard-heading">
                        Count of Houses By Bathroom
                      </span>

                      <Pie
                        data={bathroomDoughnutChart}
                        options={bathroomOptions}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="second-dashboard-btn">
                  <DashboardMap wardId={this.state.ward_id} property_id={this.props.property_id} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={3} sm={3} xs={3} className="second-dashboard-col">
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">Ward Details </span>
              <span className="second-dashboard-heading-key">
                Ward Name :
                <span className="second-dashboard-heading-value">
                  {ward_name}
                </span>
              </span>
              {ward_designation &&
                Object.keys(ward_designation).map((key) => (
                  <span className="second-dashboard-heading-key">
                    {key} :
                    <span className="second-dashboard-heading-value">
                      {ward_designation[key]}
                    </span>
                  </span>
                ))}
            </div>
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">Area Difference</span>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {areaTableColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        {/* {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))} */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {areaTableArray
                        ?.slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((value) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              // key={row.code}
                            >
                              {areaTableColumns.map((column) => {
                                const values = value[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    // align={column.align}
                                  >
                                    {values}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={areaTableArray?.length}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </Paper>
              {/* <Doughnut data={data} options={options} /> */}
            </div>
            <div className="second-dashboard-btn">
              <span className="second-dashboard-heading">
                Count of Buildings by Door Status
              </span>
              <Bar
                data={bldgCateGroupBarChart}
                options={bldgCateGroupOptions}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log("state", state);

  return {
    wardList: state.dashboard.wardList,
    secondDashboardData: state.dashboard.secondDashboardData,
    property_id: state.dashboard.property_ids,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSecondDashboardData: (id) => dispatch(getSecondDashboardData(id)),
    getWardList: () => dispatch(getWardList()),
    getPropertyID: (data) => dispatch(getPropertyID(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondDashboard);
