import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Filter.css";
import { Modal, Panel, ButtonToolbar, Button, Checkbox } from "react-bootstrap";
import closeButton from "../../../asset/svg/close_icon.svg";

class Attribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFields: [], 
    };
  }

  componentDidMount() {
    const { parameters } = this.props;
    if (parameters && parameters.length > 0) {
      const selectedFields = parameters.map((param) => param.field);
      this.setState({ selectedFields });
    }
  }

  checkedStatus = (name) => {
    const { parameters } = this.props;
    // console.log("parameters in checked status",parameters);
    let status = false;
    if (parameters.length != 0) {
      parameters.map((item, index) => {
        
        if (item.display_name == name) {
          status = true;
        }
      });
    }
    return status;
  };

  attributeList = () => {
    let filterData = [];
    for (const key in this.props.data.layer.advancefilterinfo_sorted) {
      let keys = key.toString();
      let data = {
        key: `${key}`,
        values: this.props.data.layer.advancefilterinfo_sorted[keys],
      };
      filterData.push(data);
    }
    
    // filterData.length > 0 &&
    return filterData.map((el, i) => {
      let flag = 0;

      return el.values

        .sort((a, b) => (a["display_name"] > b["display_name"] ? 1 : -1))
        .map((column, index) => {
          // console.log("coloumn values",column);
          
          // if (
          //   !(
          //     this.props.data.layer.layer_id == 2 &&
          //     column.model_name == "Establishment"
          //   ) &&
          //   !(
          //     this.props.data.layer.layer_id != 2 &&
          //     (column.model_name == "Member_Details" ||
          //       column.model_name == "Property_Other_Details" ||
          //       column.model_name == "Member_Diseases")
          //   )
          // ) {
            if (flag == 0) {
              ++flag;
              return (
                <div key={column.display_name}>
                  <p style={{ marginLeft: "0px" }}>
                    <b>{el.key}</b>
                  </p>
                  <Checkbox
                    value={column.display_name}
                    key={column.display_name}
                    checked={this.checkedStatus(column.display_name)}
                    onChange={(event) =>
                      this.props.handleInputChangeSelect(event, index)
                    }
                  >
                    {column.display_name}
                  </Checkbox>
                </div>
              );
            }

            return (
              <div key={column.display_name}>
                <Checkbox
                  value={column.display_name}
                  key={column.display_name - new Date().getTime()}
                  // key={index}
                  checked={this.checkedStatus(column.display_name)}
                  onChange={(event) =>
                    this.props.handleInputChangeSelect(event, index)
                  }
                >
                  {column.display_name}
                </Checkbox>
              </div>
            );
          // }
        });
    });
  };
  render() {
    const { closeAttri, parameters } = this.props;

    return (
      <Modal show={this.props.show} className="filterFilterModal">
        <Panel bsStyle="primary" className="filterFilterPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Filter</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closeButton}
                className="panelheadbtn"
                onClick={closeAttri}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body className="panelBody">
            {this.props.show && this.attributeList()}
          </Panel.Body>
          <Panel.Footer className="panelFooter">
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={this.props.handleInputSelectAll}
            >
              Select All
            </Button>
            {parameters && parameters.length > 0 && (
              <Button
                bsStyle="success"
                style={{ marginLeft: "3px", left: "5px" }}
                onClick={this.props.handleInputUnselectAll}
              >
                Unselect All
              </Button>
            )}
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={closeAttri}
            >
              OK
            </Button>
          </Panel.Footer>
        </Panel>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    layerColumns: state.advancedFilter.layerColumns,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attribute)
);
